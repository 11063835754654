<template>
  <div class="freight-charter-order-form">
    <h4 class="title">{{ $t("freightCharterOrder.form.enterOrderForm") }}</h4>
    <div class="form-group" :key="keyForm">
      <div class="row">
        <v-ua-input
          :is-disabled="inProcess"
          name="nameOfCompany"
          class="alt2"
          :label="$t('freightCharterOrder.form.nameOfCompany') + ' *'"
          :error="hasError('nameOfCompany')"
          v-validate="'required'"
          v-model="sendData.companyName"
        ></v-ua-input>
      </div>
      <div class="row">
        <v-ua-input
          :is-disabled="inProcess"
          type="phone"
          name="phone"
          class="alt2"
          :label="$t('freightCharterOrder.form.phone') + ' *'"
          :error="hasError('phone')"
          v-validate="{
            required: true,
            regex: phoneRegExp,
            min: 9,
          }"
          v-model="sendData.phone"
        ></v-ua-input>
      </div>
      <div class="row">
        <div class="col">
          <v-ua-input
            :is-disabled="inProcess"
            name="lastName"
            class="alt2"
            :label="$t('freightCharterOrder.form.lastName') + ' *'"
            :error="hasError('lastName')"
            v-validate="'required'"
            v-model="sendData.lastName"
          ></v-ua-input>
        </div>
        <div class="col">
          <v-ua-input
            :is-disabled="inProcess"
            name="firstName"
            class="alt2"
            :label="$t('freightCharterOrder.form.firstName') + ' *'"
            :error="hasError('firstName')"
            v-validate="'required'"
            v-model="sendData.firstName"
          ></v-ua-input>
        </div>
        <div class="col">
          <v-ua-input
            :is-disabled="inProcess"
            name="middleName"
            class="alt2"
            :label="$t('freightCharterOrder.form.middleName')"
            v-model="sendData.middleName"
          ></v-ua-input>
        </div>
      </div>
      <div class="row">
        <v-ua-input
          :is-disabled="inProcess"
          name="email"
          type="email"
          class="alt2"
          :label="$t('freightCharterOrder.form.email') + ' *'"
          :error="hasError('email')"
          v-validate="{ required: true, regex: emailRegExp }"
          v-model="sendData.email"
        ></v-ua-input>
      </div>
      <div class="row date-row">
        <div class="col">
          <v-ua-datepicker
            class="alt2"
            :is-disabled="inProcess"
            :placeholder="$t('freightCharterOrder.form.departureDate')"
            :error="hasError('departureDate')"
            v-model="sendData.departureDate"
            :min-date="yesterday"
            name="departureDate"
          ></v-ua-datepicker>
        </div>
        <div class="col">
          <v-ua-input
            :is-disabled="inProcess"
            type="time"
            name="departureTime"
            class="alt2"
            v-validate="{ regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
            placeholder="**:**"
            :label="$t('freightCharterOrder.form.departureTime')"
            :error="hasError('departureTime')"
            v-model="sendData.departureTime"
          ></v-ua-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <v-ua-input
            name="cargoVolume"
            class="alt2"
            only-digit="init"
            :is-disabled="inProcess"
            :label="$t('freightCharterOrder.form.cargoVolume')"
            :number-after-point="3"
            v-model="sendData.cargoVolume"
          ></v-ua-input>
        </div>
        <div class="col-2"></div>
        <div class="col">
          <v-ua-input
            name="сargoWeight"
            class="alt2"
            only-digit="init"
            :is-disabled="inProcess"
            :label="$t('freightCharterOrder.form.сargoWeight')"
            :number-after-point="1"
            v-model="sendData.сargoWeight"
          ></v-ua-input>
        </div>
      </div>
      <div class="row">
        <v-ua-input
          name="itinerary"
          class="alt2"
          :is-disabled="inProcess"
          :label="$t('freightCharterOrder.form.itinerary') + ' *'"
          v-validate="'required'"
          :error="hasError('itinerary')"
          v-model="sendData.itinerary"
        ></v-ua-input>
      </div>
      <div class="row">
        <v-ua-textarea
          :is-disabled="inProcess"
          class="alt1"
          :max-length="3000"
          v-model="sendData.extra"
          :label="$t('freightCharterOrder.form.extra')"
        />
      </div>
      <div class="row condition-container">
        <div class="checkbox">
          <v-ua-checkbox class="alt1" v-model="conditionConfirm" />
        </div>
        <div class="info">
          <span> {{ $t("freightCharterOrder.form.conditionText") }} </span>
          <a href="https://www.uralairlines.ru/personal_data" target="_blank">
            {{ $t("freightCharterOrder.form.conditionLink") }}.
          </a>
        </div>
      </div>
      <br />
      <div class="row" v-if="!inProcess">
        <button
          :disabled="!isReadyToSend"
          type="button"
          class="form-btn btn btn-submit"
          @click="onSubmit"
        >
          {{ $t("freightCharterOrder.form.sendRequest") }}
        </button>
      </div>
      <div class="row row-spinner" v-else>
        <v-ua-spinner></v-ua-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharterOrderForm",
  components: {},
  data() {
    return {
      /*eslint-disable */
      emailRegExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      phoneRegExp:/^(\s*)?(\+)?([- _()+*]?\d?[- _()+*]?){9,16}(\s*)?$/,
      /*eslint-enable */
      keyForm: Date.now(),
      charterOrderData: window.CharterOrderData,
      yesterday: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      conditionConfirm: false,
      inProcess: false,
      sendData: {
        companyName: "",
        phone: "",
        lastName: "",
        firstName: "",
        middleName: "",
        departureDate: null,
        departureTime: "",
        cargoVolume: "",
        сargoWeight: "",
        email: "",
        itinerary: "",
      },
      requiredFields: [
        "companyName",
        "phone",
        "lastName",
        "firstName",
        "email",
        "itinerary",
      ],
    };
  },
  computed: {
    isReadyToSend() {
      const errorsLen = this.errors.items.length;
      let fieldsEntered = true;
      this.requiredFields.forEach((field) => {
        if (!this.sendData[field]) {
          fieldsEntered = false;
        }
      });
      if (!this.conditionConfirm) {
        return false;
      }
      if (!fieldsEntered) {
        return false;
      }
      if (errorsLen) {
        return false;
      }
      return true;
    },
  },
  methods: {
    hasError(field) {
      let hasError = "";
      this.errors.items.forEach((errItem) => {
        if (errItem.field === field) {
          return (hasError = errItem.rule);
        }
      });
      return hasError;
    },
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.makeRequest();
        }
      });
    },
    emptyForm() {
      Object.keys(this.sendData).forEach((objectKey) => {
        const isDate = objectKey === "departureDate";
        this.sendData[objectKey] = isDate ? null : "";
      });
      this.keyForm = Date.now();
      this.conditionConfirm = false;
    },
    makeRequest() {
      let bodyFormData = new FormData();
      Object.keys(this.sendData).forEach((objectKey) => {
        let value = this.sendData[objectKey];
        if (value) {
          if (objectKey === "departureDate") {
            value = this.moment(value).format("DD.MM.YYYY");
          }
          bodyFormData.append(objectKey, value);
        }
      });

      this.inProcess = true;
      this.$axios
        .post(this.charterOrderData.ajaxUrl, bodyFormData)
        .then((response) => {
          let message = this.$t("freightCharterOrder.form.successMessage");
          if (response.status !== 200) {
            message = this.$t("freightCharterOrder.form.errorMessage");
          }
          this.emptyForm();
          this.$buefy.dialog.alert({
            message: message,
            type: "is-success",
          });
          this.inProcess = false;
        })
        .catch(() => {
          this.inProcess = false;
        });
    },
  },
};
</script>
