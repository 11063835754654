import { TripResponseCity, TripResponseFlight } from "./tripsResponse";

export class TrackingResponse {
  constructor(data) {
    // простые поля
    ["disruption_in_way", "mawb", "pieces", "shcs", "volume", "weight"].forEach(
      (field) => {
        this[field] = data[field];
      }
    );

    // объекты
    if (data.destination) {
      this.destination = new TripResponseCity(data.destination);
    }
    if (data.origin) {
      this.origin = new TripResponseCity(data.origin);
    }
    this.latestBookings = [];
    if (data.latest_bookings && data.latest_bookings.length) {
      this.latestBookings = data.latest_bookings.map(
        (itemData) => new TrackingResponseLatestBooking(itemData)
      );
    }
    this.movements = [];
    if (data.movements && data.movements.length) {
      this.movements = data.movements.map(
        (itemData) => new TrackingResponseMovement(itemData)
      );
    }

    this.piecesInAirports = [];
    if (data.pieces_in_airports && data.pieces_in_airports.length) {
      this.piecesInAirports = data.pieces_in_airports.map(
        (itemData) => new TrackingResponsePieceInAirport(itemData)
      );
    }
  }
}

export class TrackingResponseLatestBooking {
  constructor(data) {
    // простые поля
    [
      "destination_iata",
      "mawb",
      "pieces",
      "origin_iata",
      "volume",
      "weight",
    ].forEach((field) => {
      this[field] = data[field];
    });

    // объекты
    if (data.flight) {
      this.flight = new TripResponseFlight(data.flight);
    }
  }
}
export class TrackingResponsePieceInAirport {
  constructor(data) {
    // простые поля
    ["iata", "pieces"].forEach((field) => {
      this[field] = data[field];
    });
  }
}

export class TrackingResponseMovement {
  constructor(data) {
    // простые поля
    [
      "active",
      "description",
      "description_cyrillic",
      "mawb",
      "pieces",
      "status_code",
      "volume",
      "weight",
    ].forEach((field) => {
      this[field] = data[field];
    });

    // объекты
    if (data.location) {
      this.location = new TripResponseCity(data.location);
    }
    if (data.flight) {
      this.flight = new TripResponseFlight(data.flight);
    }

    // время как есть
    ["moved_at", "time_utc"].forEach((field) => {
      this[field] = data[field]
        ? window.Vue.prototype.stringDate2Moment(data[field])
        : null;
    });
    // время с преобразованием к текущей таймзоне
    ["time_local"].forEach((field) => {
      this[field] = data[field]
        ? window.Vue.prototype.stringDate2Moment(data[field], true)
        : null;
    });
  }

  get descriptionText() {
    return window.Vue.prototype.currentLocale() === "ru"
      ? this.description_cyrillic
      : this.description;
  }
  get timeUtc() {
    return this.time_utc.format("DD.MM.YYYY, HH:mm");
  }
}
