<template>
  <div>
    <div
      class="trips-form--steps-inner"
      v-for="(flight, findex) in item.flights"
      :key="findex"
    >
      <div class="trips-form--steps-item">
        <div class="trips-form--steps-flightNumber">
          {{ flight.flightNumber }}
        </div>
        <div class="trips-form--steps-route">
          <div class="trips-form--steps-value">
            <p class="trips-form--steps-time">
              {{ flight.departureTimeDate }} {{ flight.departureTimeHour }}
            </p>
            <p>{{ flight.origin.name }} ({{ flight.origin.iata }})</p>
          </div>
          <div class="trips-form--steps-figures">
            <div
              class="trips-form--steps-circle trips-form--steps-circle__left"
            ></div>
            <div class="trips-form--steps-line"></div>
            <div
              class="trips-form--steps-circle trips-form--steps-circle__right"
            ></div>
          </div>
          <div class="trips-form--steps-value">
            <p class="trips-form--steps-time">
              {{ flight.arrivalTimeDate }} {{ flight.arrivalTimeHour }}
            </p>
            <p>{{ flight.destination.name }} ({{ flight.destination.iata }})</p>
          </div>
        </div>

        <div class="trips-form--steps-equipment">
          {{ flight.equipment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TripsShowSteps",
  props: ["item"],
};
</script>
