<template>
  <div class="js-shcs-block trips-form--dropdown-inner" v-on-clickaway="close">
    <div
      v-if="!isEdit && value.length > 0"
      @click="edit"
      class="trips-form--dropdown-filled"
    >
      <div
        class="js-shcs-block"
        style="display: inline-block"
        v-for="(val, index) in checkboxs"
        :key="index"
      >
        <span
          class="js-shcs-block"
          style="
            outline: 1px solid #ffffff;
            padding: 2px 5px;
            margin-right: 5px;
          "
        >
          <span
            @click.stop="del(index)"
            style="cursor: pointer"
            class="js-shcs-block trips-form--close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="10px"
              height="10px"
              viewBox="0 0 10 10"
              version="1.1"
            >
              <g id="surface1">
                <path
                  d="M 10 0.589844 L 9.421875 0.0117188 L 5 4.421875 L 0.578125 0.0117188 L 0 0.589844 L 4.421875 5 L 0 9.410156 L 0.578125 9.988281 L 5 5.578125 L 9.421875 9.988281 L 10 9.410156 L 5.578125 5 Z M 10 0.589844 "
                />
              </g>
            </svg>
          </span>
          {{ val }}
        </span>
      </div>
    </div>
    <div
      class="js-shcs-block trips-form--dropdown-placeholder"
      v-if="!isEdit && value.length == 0"
      @click="edit"
    >
      {{ placeholder }}
    </div>
    <ul class="js-shcs-block trips-form--dropdown-list" v-if="isEdit">
      <li class="js-shcs-block" v-for="(group, gindex) in shcs" :key="gindex">
        <h5 class="js-shcs-block">{{ group.group }}</h5>
        <ul class="js-shcs-block">
          <li
            v-for="(item, oindex) in group.items"
            :key="oindex"
            class="js-shcs-block"
          >
            <b-checkbox
              class="js-shcs-block"
              v-model="checkboxs"
              :native-value="item.code"
              :disabled="
                checkboxs.length >= 3 && !checkboxs.includes(item.code)
              "
              :key="oindex"
              @input="change"
            >
              <span class="js-shcs-block trips-form--dropdown-name">{{
                item.name
              }}</span>
              <span class="js-shcs-block trips-form--dropdown-code"
                >({{ item.code }})</span
              >
            </b-checkbox>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
export default {
  name: "TripShcsSelect",
  props: ["value", "shcs", "placeholder"],
  directives: {
    onClickaway: onClickaway,
  },
  created() {
    this.shcs.forEach((group) => {
      group.items.forEach((item) => {
        if (this.value.includes(item.code)) {
          this.checkboxs.push(item.code);
        }
      });
    });
  },
  data() {
    return {
      isEdit: false,
      checkboxs: [],
    };
  },
  methods: {
    edit() {
      this.isEdit = true;
    },
    close(e) {
      let node = e.target;
      let foundWrap = false;
      while (node) {
        if (node.classList && node.classList.contains("js-shcs-block")) {
          foundWrap = true;
          break;
        }
        node = node.parentNode;
      }
      if (!foundWrap) {
        this.isEdit = false;
      }
    },
    change() {
      this.$emit("input", this.checkboxs);
    },
    del(index) {
      this.checkboxs = this.checkboxs.filter((item, indx) => indx != index);
      this.change();
    },
  },
};
</script>

<style scoped></style>
