<template>
  <div class="freight-charter-order">
    <div>
      <h3>
        <center>{{ $t("freightCharterOrder.text.header") }}</center>
      </h3>
    </div>
    <div>
      {{ $t("freightCharterOrder.text.paragraph1") }}
    </div>
    <br />
    <div>
      {{ $t("freightCharterOrder.text.paragraph2") }}
    </div>
    <br />
    <div>
      <p align="center">
        <img
          src="https://i.ibb.co/wMXWm41/map.jpg"
          :alt="$t('freightCharterOrder.text.footnote')"
        />
      </p>
      <p align="center">{{ $t("freightCharterOrder.text.footnote") }}</p>
    </div>
    <div>
      {{ $t("freightCharterOrder.text.paragraph3") }}
      <br />
    </div>
    <br />
    <br />
    <div>
      {{ $t("freightCharterOrder.text.paragraph4") }}
    </div>
    <br />
    <div>
      {{ $t("freightCharterOrder.text.paragraph5") }}
      <a
        href="/upload/docs/Презентация%20Грузоперевозки%20Уральские%20авиалинии.pdf"
      >
        {{ $t("freightCharterOrder.text.linkHere") }}.</a
      >
    </div>
    <br />
    <div>
      {{ $t("freightCharterOrder.text.paragraph6") }}: <br />
      <br />
      {{ $t("freightCharterOrder.text.phone") }}.: +7(343)312-90-58 <br />
    </div>
    <br />
    <div>E-mail: <a href="mailto:cargosales@u6.ru">cargosales@u6.ru</a></div>
    <br />
    <br />
    <charter-order-form />
  </div>
</template>

<script>
import CharterOrderForm from "./CharterOrderForm.vue";

export default {
  name: "FreightCharterOrder",
  components: { CharterOrderForm },
};
</script>
