<template>
  <div class="ua-textarea" :class="classes">
    <div class="empty-space" />
    <label class="ua-textarea--label" v-if="label">{{ label }}</label>
    <textarea
      v-model="newValue"
      :maxlength="maxLength"
      :disabled="isDisabled"
      @focus="isEdit = true"
      @blur="isEdit = false"
      @input="$emit('input', newValue)"
    />
    <div v-if="maxLength" class="limit-container">
      {{ $t("freightCharterOrder.form.leftToEnter") }}: {{ leftToEnter }} /
      {{ maxLength }}
    </div>
  </div>
</template>

<script>
export default {
  name: "UaTextarea",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    rules: {
      type: String,
    },
    label: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const activeClass = this.newValue || this.isEdit ? "active" : "";
      const errorClass = this.error ? "error" : "";
      const successClass = !this.error && this.newValue ? "success" : "";
      return [activeClass, errorClass, successClass];
    },
    leftToEnter() {
      return this.maxLength - this.newValue.length;
    },
  },
  data() {
    return {
      newValue: "",
      isEdit: false,
    };
  },
  methods: {},
  created() {
    this.newValue = this.value || "";
  },
};
</script>
