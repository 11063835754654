<template>
  <div class="trips-form--result">
    <div v-for="(item, index) in list" :key="index">
      <div class="trips-form--result-item">
        <div
          class="trips-form--flight"
          v-if="item.flights && item.flights.length"
        >
          {{ item.flights[0].flightNumber }}
        </div>
        <div class="trips-form--result-content">
          <div class="trips-form--from">
            <p class="trips-form--place">
              {{ item.origin.name }} ({{ item.origin.iata }})
            </p>
            <p class="trips-form--time">{{ item.departureTimeHour }}</p>
            <p class="trips-form--date">{{ item.departureTimeDate }}</p>
          </div>
          <div class="trips-form--relation">
            <div class="trips-form--circle trips-form--circle__from"></div>
            <div class="trips-form--line"></div>
            <div class="trips-form--circle trips-form--circle__to"></div>
            <p class="trips-form--duration">
              {{ item.duration.hours }} {{ $t("trips.list.hours") }}
              {{ item.duration.minutes }} {{ $t("trips.list.minutes") }}
            </p>
            <button
              type="button"
              class="trips-form--steps-btn btn btn-link"
              @click="item.showSteps = !item.showSteps"
            >
              <span v-if="item.connections == 0">
                {{ $t("trips.list.statusStep.noStep") }}
              </span>
              <span v-if="item.connections > 0">
                {{ $t("trips.list.statusStep.isStep") }}
              </span>
            </button>
          </div>
          <div class="trips-form--to">
            <p class="trips-form--place">
              {{ item.destination.name }} ({{ item.destination.iata }})
            </p>
            <p class="trips-form--time">{{ item.arrivalTimeHour }}</p>
            <p class="trips-form--date">{{ item.arrivalTimeDate }}</p>
          </div>
          <button
            type="button"
            class="trips-form--steps-btn trips-form--steps-btn__mobile btn btn-link"
            @click="item.showSteps = !item.showSteps"
          >
            <span v-if="item.connections == 0">
              {{ $t("trips.list.statusStep.noStep") }}
            </span>
            <span v-if="item.connections > 0">
              {{ $t("trips.list.statusStep.isStep") }}
            </span>
          </button>
        </div>
      </div>
      <TripsShowSteps :item="item" v-show="item.showSteps"></TripsShowSteps>
    </div>
  </div>
</template>

<script>
import TripsShowSteps from "./TripsShowSteps.vue";

export default {
  name: "TripList",
  props: ["list"],
  components: { TripsShowSteps },
};
</script>
