<template>
  <div class="col-xl-12">
    <form class="contacts-form--form" autocomplete="off">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-12 col-md-5">
          <v-point
            v-model="formData.country_alpha_2"
            :points="countries"
            :placeholder="$t('contacts.form.labels.country')"
            :empty-result="$t('contacts.form.autocompleteEmpty')"
            v-validate="{ required: !formData.airport_iata }"
            key="country"
            name="country"
            class="point"
            @input="checkValidate('country_alpha_2')"
            :class="{ point__dark: !toHeader, point__transparent: toHeader }"
            :error="$t('contacts.form.errors.country')"
            :invalid="errors.first('country') && !changedFields.country_alpha_2"
          ></v-point>
        </div>
        <div class="col-sm-12 col-md-5">
          <v-point
            v-model="formData.airport_iata"
            :points="airports"
            :placeholder="$t('contacts.form.labels.city')"
            :empty-result="$t('contacts.form.autocompleteEmpty')"
            v-validate="{ required: !formData.country_alpha_2 }"
            key="origin"
            name="origin"
            class="point"
            @input="checkValidate('airport_iata')"
            :class="{ point__dark: !toHeader, point__transparent: toHeader }"
            :country="formData.country_alpha_2"
            :error="$t('contacts.form.errors.city')"
            :invalid="errors.first('origin') && !changedFields.airport_iata"
          ></v-point>
        </div>
        <div class="col-sm-12 col-md-2">
          <button
            type="button"
            class="form-btn btn btn-primary"
            @click="send()"
          >
            {{ $t("contacts.form.submitButton") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactsForm",
  props: ["countries", "airports", "airportIata", "countryAlpha", "toHeader"],
  data() {
    return {
      formData: {
        airport_iata: null,
        country_alpha_2: null,
      },
      showOptions: false,
      changedFields: {
        country_alpha_2: false,
        airport_iata: false,
      },
    };
  },
  watch: {
    airports() {
      this.initFormData();
    },
    countries() {
      this.initFormData();
    },
  },
  methods: {
    initFormData() {
      if (this.airportIata) {
        const airport_iata = this.airports.filter(
          (airport) =>
            this.airportIata.toLowerCase() == airport.iata.toLowerCase()
        );
        if (airport_iata && airport_iata.length > 0) {
          this.formData.airport_iata = airport_iata[0];
        }
      }
      if (this.countryAlpha) {
        const country_alpha_2 = this.countries.filter(
          (country) =>
            this.countryAlpha.toString().toLowerCase() ==
            country.alpha2.toLowerCase()
        );
        if (country_alpha_2 && country_alpha_2.length > 0) {
          this.formData.country_alpha_2 = country_alpha_2[0];
        }
      }
      if (this.formData.airport_iata || this.formData.country_alpha_2) {
        window.setTimeout(() => {
          this.send();
        }, 2000);
      }
    },
    checkValidate(field) {
      this.changedFields[field] = true;
      if (field === "country_alpha_2") {
        this.changedFields.airport_iata = false;
        this.formData.airport_iata = null;
      }
    },
    send() {
      this.$validator.validate().then((result) => {
        this.changedFields.country_alpha_2 = false;
        this.changedFields.airport_iata = false;
        if (result) {
          let [airport_iata, airport_id, country_alpha_2, country_id] =
            new Array(4).fill(null);
          if (this.formData.airport_iata) {
            airport_iata = this.formData.airport_iata.iata;
            airport_id = this.formData.airport_iata.id;
          }
          if (this.formData.country_alpha_2) {
            country_alpha_2 = this.formData.country_alpha_2.alpha2;
            country_id = this.formData.country_alpha_2.id;
          }
          this.$emit("submit", {
            airport_iata,
            airport_id,
            country_alpha_2,
            country_id,
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
