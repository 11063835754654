<template>
  <div class="loader-container">
    <div class="dot-flashing"></div>
  </div>
</template>

<script>
export default {
  name: "UaSpinner",
};
</script>

<style scoped>
/**
   * ==============================================
   * Dot Flashing
   * ==============================================
   */
.loader-container {
  margin: 0 auto;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4d5254;
  color: #4d5254;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4d5254;
  color: #4d5254;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4d5254;
  color: #4d5254;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #4d5254;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
