import moment from "moment";
import axios from "axios";

window.Vue.prototype.lang = function () {
  return navigator.language || navigator.userLanguage;
};

window.Vue.prototype.serialize2GetQuery = function (obj) {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== null) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};

window.Vue.prototype.currentLocale = function () {
  const app = document.getElementById("app");
  let locale = app.getAttribute("locale");

  if (locale !== undefined && locale.length === 2) {
    return locale;
  } else {
    return "ru";
  }
};

window.Vue.prototype.hrefTarget = function (item) {
  return item.target ? "_blank" : "_self";
};

window.Vue.prototype.moment = moment;

window.Vue.prototype.stringDate2Moment = function (strDate, catTz) {
  if (catTz) {
    strDate = strDate.replace(/([+].*)$/gi, "");
  }
  return window.Vue.prototype.moment(strDate);
};

window.Vue.prototype.$axios = axios.create({
  headers: { "Content-Type": "multipart/form-data" },
});

window.Vue.prototype.$axiosJson = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});
