export class TripsResponseTransformer {
  constructor(response) {
    this.items = [];
    if (response && response.length) {
      response.forEach((item) => {
        if (item) {
          this.items.push(new TripResponseItem(item));
        }
      });
    }
  }

  getItems() {
    return this.items;
  }
}

export class TripResponseItem {
  constructor(item) {
    // простые поля
    ["available", "comment", "rates", "connections"].forEach((field) => {
      this[field] = item[field];
    });

    // время как есть
    ["sta", "std"].forEach((field) => {
      this[field] = item[field]
        ? window.Vue.prototype.stringDate2Moment(item[field])
        : null;
    });
    // время с преобразованием к текущей таймзоне
    ["sta_local", "std_local"].forEach((field) => {
      this[field] = item[field]
        ? window.Vue.prototype.stringDate2Moment(item[field], true)
        : null;
    });

    // объекты
    this.destination = new TripResponseCity(item.destination);
    this.origin = new TripResponseCity(item.origin);
    this.flights = [];
    if (item.segments && item.segments.length) {
      item.segments.forEach((segment) => {
        this.flights.push(new TripResponseFlight(segment));
      });
    }
    this.showSteps = false;
  }

  get departureTimeHour() {
    return this.std_local.format("HH:mm");
  }

  get arrivalTimeHour() {
    return this.sta_local.format("HH:mm");
  }

  get departureTimeDate() {
    return this.std_local.format("DD.MM");
  }

  get arrivalTimeDate() {
    return this.sta_local.format("DD.MM");
  }

  get departureTime() {
    return this.std_local.format("DD.MM, HH:mm");
  }

  get arrivalTime() {
    return this.sta_local.format("DD.MM, HH:mm");
  }

  get duration() {
    const interval = window.Vue.prototype.moment.duration(
      this.sta.diff(this.std)
    );
    return {
      hours: interval.get("hours"),
      minutes: interval.get("minutes"),
    };
  }
}

export class TripResponseCity {
  constructor(city) {
    // простые поля
    [
      "city_name",
      "cyrillic_city_name",
      "iata",
      "id",
      "latitude",
      "longitude",
    ].forEach((field) => {
      this[field] = city[field];
    });
  }

  get name() {
    const name =
      window.Vue.prototype.currentLocale() === "ru"
        ? this.cyrillic_city_name
        : this.city_name;
    return name ? name : this.iata;
  }
}

const flights = [
  {
    iata: "319",
    name: "Airbus A319",
  },
  {
    iata: "320",
    name: "Airbus A320-100/200",
  },
  {
    iata: "321",
    name: "Airbus A321-100/200",
  },
  {
    iata: "7M8",
    name: "Boeing 737 MAX 8",
  },
  {
    iata: "32Q",
    name: "Airbus A-321neo",
  },
];

export class TripResponseFlight {
  constructor(flight) {
    // простые поля
    [
      "aircraft_reg",
      "aircraft_type",
      "airline",
      "segment_number",
      "number",
      "id",
      "number_with_date",
      "destination_iata",
      "origin_iata",
    ].forEach((field) => {
      this[field] = flight[field] ? flight[field] : "";
    });

    // время как есть
    ["sta", "std"].forEach((field) => {
      this[field] = flight[field]
        ? window.Vue.prototype.stringDate2Moment(flight[field])
        : null;
    });
    // время с преобразованием к текущей таймзоне
    ["sta_local", "std_local"].forEach((field) => {
      this[field] = flight[field]
        ? window.Vue.prototype.stringDate2Moment(flight[field], true)
        : null;
    });

    // объекты
    if (flight.destination) {
      this.destination = new TripResponseCity(flight.destination);
    }
    if (flight.origin) {
      this.origin = new TripResponseCity(flight.origin);
    }
  }

  get equipment() {
    const equipment = flights.find(
      (airplane) => airplane.iata == this.aircraft_type
    );
    return equipment ? equipment.name : this.aircraft_type;
  }
  get flightNumber() {
    return (
      this.airline + (this.segment_number ? this.segment_number : this.number)
    );
  }

  get departureTimeUTC() {
    return this.std.format("DD.MM, HH:mm");
  }

  get departureTimeHour() {
    return this.std_local.format("HH:mm");
  }

  get arrivalTimeHour() {
    return this.sta_local.format("HH:mm");
  }

  get departureTimeDate() {
    return this.std_local.format("DD.MM");
  }

  get arrivalTimeDate() {
    return this.sta_local.format("DD.MM");
  }

  get departureTime() {
    return this.std_local.format("DD.MM, HH:mm");
  }

  get arrivalTime() {
    return this.sta_local.format("DD.MM, HH:mm");
  }

  get duration() {
    const interval = window.Vue.prototype.moment.duration(
      this.sta.diff(this.std)
    );
    return {
      hours: interval.get("hours"),
      minutes: interval.get("minutes"),
    };
  }
}
