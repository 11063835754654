<template>
  <div>
    <SearchFlexForm v-if="redirect" @submit="send"></SearchFlexForm>
    <div v-if="!redirect">
      <div class="row">
        <SearchForm @submit="send"></SearchForm>
      </div>
      <div class="row" v-if="!loading && empty">
        {{ $t("search.list.emptyResult") }}
      </div>
      <div class="row" v-if="!loading && sendError">
        {{ $t("search.list.sendError") }}
      </div>
      <div class="row" v-if="!loading && list && list.length > 0">
        <SearchList :list="list"></SearchList>
        <div class="row" style="width: 100%" v-if="count > page">
          <button
            class="search-form--btn btn btn-primary"
            type="button"
            @click="send(query, true)"
            v-show="!loadingAdd"
          >
            {{ $t("search.list.showMore") }}
          </button>
          <v-ua-spinner key="add_loader" v-show="loadingAdd"></v-ua-spinner>
        </div>
      </div>
      <div class="row" v-if="loading">
        <v-ua-spinner key="main_loader"></v-ua-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import SearchForm from "./SearchForm.vue";
import SearchList from "./SearchList.vue";
import SearchFlexForm from "./SearchFlexForm.vue";

export default {
  name: "Search",
  props: ["redirect"],
  data() {
    return {
      searchData: window.SearchData,
      list: [],
      loading: false,
      loadingAdd: false,
      empty: false,
      sendError: false,
      query: "",
      page: 1,
      count: 1,
    };
  },
  components: { SearchForm, SearchList, SearchFlexForm },
  methods: {
    send(event, add) {
      this.query = event;
      if (!add) {
        this.page = 1;
        this.count = 1;
      } else {
        this.page++;
      }
      if (this.redirect) {
        window.location.href =
          this.redirect + "?" + this.serialize2GetQuery({ query: event });
      } else {
        if (!add) {
          this.loading = true;
          this.empty = false;
          this.sendError = false;
        } else {
          this.loadingAdd = true;
        }
        const request = {
          q: event.replace(/\s+/gi, "+"),
          tags: this.currentLocale() + "kstl",
        };
        if (this.page > 1) {
          request.PAGEN_1 = this.page;
        }
        this.$axiosJson
          .get(this.searchData.ajaxUrl + "?" + this.serialize2GetQuery(request))
          .then((response) => {
            if (response.status === 200) {
              if (response.data && response.data.search) {
                this.page = response.data.page;
                this.count = response.data.count;
                if (add) {
                  response.data.search.forEach((item) => {
                    this.list.push(item);
                  });
                } else {
                  this.list = response.data.search;
                }
              } else {
                if (response.data.error) {
                  console.error("search error =>", response.data.error);
                  this.sendError = true;
                } else {
                  this.list = [];
                }
              }
            }
            if ((!this.list || this.list.length == 0) && !this.sendError) {
              this.empty = true;
            }
            this.loading = false;
            this.loadingAdd = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
