<template>
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <table class="tracking-form--table tracking-form--table__basic table">
          <thead>
            <tr>
              <th>{{ $t("tracking.list.tableCommon.thead.mawb") }}</th>
              <th>{{ $t("tracking.list.tableCommon.thead.origin") }}</th>
              <th>{{ $t("tracking.list.tableCommon.thead.destination") }}</th>
              <th>{{ $t("tracking.list.tableCommon.thead.pieces") }}</th>
              <th>{{ $t("tracking.list.tableCommon.thead.weight") }}</th>
              <th>{{ $t("tracking.list.tableCommon.thead.volume") }}</th>
              <!-- <th>
                {{ $t("tracking.list.tableCommon.thead.disruptionInWay") }}
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td :data-label="$t('tracking.list.tableCommon.thead.mawb')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.mawb")
                }}</span>
                <div class="tracking-form--value">
                  {{ list.mawb }}
                </div>
              </td>
              <td :data-label="$t('tracking.list.tableCommon.thead.origin')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.origin")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="list.origin"
                    >{{ list.origin.name }} ({{ list.origin.iata }})</template
                  >
                </div>
              </td>
              <td
                :data-label="$t('tracking.list.tableCommon.thead.destination')"
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.destination")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="list.destination"
                    >{{ list.destination.name }} ({{
                      list.destination.iata
                    }})</template
                  >
                </div>
              </td>
              <td :data-label="$t('tracking.list.tableCommon.thead.pieces')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.pieces")
                }}</span>
                <div class="tracking-form--value">
                  {{ list.pieces }}
                </div>
              </td>
              <td :data-label="$t('tracking.list.tableCommon.thead.weight')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.weight")
                }}</span>
                <div class="tracking-form--value">
                  {{ list.weight }}
                </div>
              </td>
              <td :data-label="$t('tracking.list.tableCommon.thead.volume')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.volume")
                }}</span>
                <div class="tracking-form--value">
                  {{ list.volume }}
                </div>
              </td>
              <!-- <td
                :data-label="
                  $t('tracking.list.tableCommon.thead.disruptionInWay')
                "
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.tableCommon.thead.disruptionInWay")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="list.disruption_in_way"
                    >{{
                      $t("tracking.list.tableCommon.disruptionInWayStatus.yes")
                    }}
                  </template>
                  <template v-if="!list.disruption_in_way"
                    >{{
                      $t("tracking.list.tableCommon.disruptionInWayStatus.no")
                    }}
                  </template>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="row"
      v-if="list.latestBookings && list.latestBookings.length > 0"
    >
      <div class="col-sm-12">
        <h3>{{ $t("tracking.list.latestBookings.h2") }}</h3>
        <table
          class="tracking-form--table tracking-form--table__bookings table"
        >
          <thead>
            <tr>
              <th>
                {{ $t("tracking.list.latestBookings.thead.flightNumber") }}
              </th>
              <th>
                {{ $t("tracking.list.latestBookings.thead.departureTime") }}
              </th>
              <th>{{ $t("tracking.list.latestBookings.thead.origin") }}</th>
              <th>
                {{ $t("tracking.list.latestBookings.thead.destination") }}
              </th>
              <th>
                {{ $t("tracking.list.latestBookings.thead.piecesWeight") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list.latestBookings" :key="index">
              <td
                :data-label="
                  $t('tracking.list.latestBookings.thead.flightNumber')
                "
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.latestBookings.thead.flightNumber")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="item.flight"
                    >{{ item.flight.flightNumber }}
                  </template>
                </div>
              </td>
              <td
                :data-label="
                  $t('tracking.list.latestBookings.thead.departureTime')
                "
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.latestBookings.thead.departureTime")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="item.flight">{{
                    item.flight.departureTimeUTC
                  }}</template>
                </div>
              </td>
              <td :data-label="$t('tracking.list.latestBookings.thead.origin')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.latestBookings.thead.origin")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.origin_iata }}
                </div>
              </td>
              <td
                :data-label="
                  $t('tracking.list.latestBookings.thead.destination')
                "
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.latestBookings.thead.destination")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.destination_iata }}
                </div>
              </td>
              <td
                :data-label="
                  $t('tracking.list.latestBookings.thead.piecesWeight')
                "
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.latestBookings.thead.piecesWeight")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.pieces }} / {{ item.weight }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="list.movements && list.movements.length > 0">
      <div class="col-sm-12">
        <h3>{{ $t("tracking.list.movements.h2") }}</h3>
        <table
          class="tracking-form--table tracking-form--table__movements table"
        >
          <thead>
            <tr>
              <th>{{ $t("tracking.list.movements.thead.location") }}</th>
              <th>{{ $t("tracking.list.movements.thead.status") }}</th>
              <th>{{ $t("tracking.list.movements.thead.flightNumber") }}</th>
              <th>{{ $t("tracking.list.movements.thead.piecesWeight") }}</th>
              <th>{{ $t("tracking.list.movements.thead.date") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list.movements" :key="index">
              <td :data-label="$t('tracking.list.movements.thead.location')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.movements.thead.location")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="item.location">
                    {{ item.location.name }} ({{ item.location.iata }})
                  </template>
                </div>
              </td>
              <td :data-label="$t('tracking.list.movements.thead.status')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.movements.thead.status")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.descriptionText }}
                </div>
              </td>
              <td
                :data-label="$t('tracking.list.movements.thead.flightNumber')"
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.movements.thead.flightNumber")
                }}</span>
                <div class="tracking-form--value">
                  <template v-if="item.flight">{{
                    item.flight.flightNumber
                  }}</template>
                  <template v-if="!item.flight">--</template>
                </div>
              </td>
              <td
                :data-label="$t('tracking.list.movements.thead.piecesWeight')"
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.movements.thead.piecesWeight")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.pieces }} / {{ item.weight }}
                </div>
              </td>
              <td :data-label="$t('tracking.list.movements.thead.date')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.movements.thead.date")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.timeUtc }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="row"
      style="display: none"
      v-if="list.piecesInAirports && list.piecesInAirports.length > 0"
    >
      <div class="col-sm-12">
        <h3>{{ $t("tracking.list.piecesInAirports.h2") }}</h3>
        <table
          class="tracking-form--table tracking-form--table__airports table table-responsive"
        >
          <thead>
            <tr>
              <th>{{ $t("tracking.list.piecesInAirports.thead.iata") }}</th>
              <th>{{ $t("tracking.list.piecesInAirports.thead.pieces") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list.piecesInAirports" :key="index">
              <td :data-label="$t('tracking.list.piecesInAirports.thead.iata')">
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.piecesInAirports.thead.iata")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.iata }}
                </div>
              </td>
              <td
                :data-label="$t('tracking.list.piecesInAirports.thead.pieces')"
              >
                <span class="tracking-form--mobile-caption">{{
                  $t("tracking.list.piecesInAirports.thead.pieces")
                }}</span>
                <div class="tracking-form--value">
                  {{ item.pieces }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingList",
  props: ["list"],
};
</script>
