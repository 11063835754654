export class TripsFormData {
  constructor(data, airports) {
    this.date = new Date();
    this.origin = null;
    this.destination = null;
    this.pieces = null;
    this.weight = null;
    this.volume = null;
    this.shcs = [];
    if (data && airports && airports.length) {
      if (data.date && /^\d{4}-\d{2}-\d{2}$/.test(data.date)) {
        this.date = new Date(data.date);
      }
      if (data.shcs && /^[[][\w,]+]$/.test(data.shcs)) {
        this.shcs = data.shcs.replace(/^[[]([\w,]+)]$/, "$1").split(",");
      }
      if (data.pieces && !isNaN(parseInt(data.pieces))) {
        this.pieces = parseInt(data.pieces);
      }
      if (data.weight && !isNaN(parseFloat(data.weight.replace(",", ".")))) {
        this.weight = parseFloat(data.weight.replace(",", "."));
      }
      if (data.volume && !isNaN(parseFloat(data.volume.replace(",", ".")))) {
        this.volume = parseFloat(data.volume.replace(",", "."));
      }
      if (data.origin) {
        const origin = airports.filter(
          (airport) => data.origin.toLowerCase() == airport.iata.toLowerCase()
        );
        if (origin && origin.length > 0) {
          this.origin = origin[0];
        }
      }
      if (data.destination) {
        const destination = airports.filter(
          (airport) =>
            data.destination.toLowerCase() == airport.iata.toLowerCase()
        );
        if (destination && destination.length > 0) {
          this.destination = destination[0];
        }
      }
    }
  }
  getData() {
    const result = {};
    if (this.date) {
      result.departure = window.Vue.prototype
        .moment(this.date)
        .format("YYYY-MM-DD");
    }

    if (this.origin) {
      result.origin = this.origin.iata;
    }

    if (this.destination) {
      result.destination = this.destination.iata;
    }

    if (this.pieces && !isNaN(parseInt(this.pieces))) {
      result.pieces = parseInt(this.pieces);
    }

    if (this.weight) {
      let value = this.weight.toString().replace(",", ".");
      if (!isNaN(parseFloat(value))) {
        result.weight = parseFloat(value);
      }
    }

    if (this.volume) {
      let value = this.volume.toString().replace(",", ".");
      if (!isNaN(parseFloat(value))) {
        result.volume = parseFloat(value);
      }
    }
    if (this.shcs.length > 0) {
      result.shcs = "[" + this.shcs.slice(0, 3).join(",") + "]";
    }
    return result;
  }
}
