<template>
  <form class="tracking-form--form" autocomplete="off">
    <div class="row align-items-start">
      <div class="col-sm-12 col-md-9 col-xl-10">
        <div class="tracking-form--field form-group">
          <masked-input
            v-model="mawb"
            mask="111-11111111"
            name="mawb"
            placeholder-char="_"
            :placeholder="$t('tracking.form.labels.mawb')"
            type="text"
            @input="checkValidate"
            v-validate="{ required: true, regex: /^\d{3}-\d{8}$/ }"
            class="tracking-form--input form-control"
            :class="{ 'is-invalid': errors.first('mawb') }"
          />
          <div
            class="tracking-form--error invalid-feedback"
            v-show="!changedField"
          >
            {{ $t("tracking.form.errors.mawb") }}
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-xl-2">
        <button
          type="button"
          class="tracking-form--btn btn btn-primary"
          @click="send()"
        >
          {{ $t("tracking.form.submitButton") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import MaskedInput from "vue-masked-input";

export default {
  name: "TrackingForm",
  components: {
    MaskedInput,
  },
  data() {
    return {
      mawb: "",
      changedField: false,
    };
  },
  mounted() {
    if (
      window.TrackingData.mawb &&
      /^\d{3}-\d{8}$/.test(window.TrackingData.mawb)
    ) {
      this.mawb = window.TrackingData.mawb;
      this.send();
    }
  },
  methods: {
    checkValidate() {
      this.changedField = true;
    },
    send() {
      this.$validator.validate().then((result) => {
        this.changedField = false;
        if (result) {
          this.$emit("submit", this.mawb);
        }
      });
    },
  },
};
</script>
