<template>
  <div>
    <div
      class="search-form--result-item"
      v-for="(item, index) in list"
      :key="index"
    >
      <p>
        <a :href="item.url">{{ item.title }}</a>
      </p>
      <p v-html="item.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchList",
  props: ["list"],
};
</script>
