<template>
  <div class="tracking-form wrapper">
    <div class="row">
      <TrackingForm @submit="send"></TrackingForm>
    </div>
    <div class="row justify-content-center" v-if="!loading && empty">
      {{ $t("tracking.list.emptyResult") }}
    </div>
    <div class="row justify-content-center" v-if="!loading && sendError">
      {{ $t("tracking.list.sendError") }}
    </div>
    <div
      class="row justify-content-center"
      v-if="
        !loading &&
        !sendError &&
        !empty &&
        (!list || list.length == 0) &&
        !redirect
      "
    >
      {{ $t("tracking.list.defaultText") }}
    </div>
    <div class="row tracking-form--results" v-if="!loading && list">
      <TrackingList :list="list"></TrackingList>
    </div>
    <div class="row" v-if="loading">
      <v-ua-spinner></v-ua-spinner>
    </div>
  </div>
</template>

<script>
import { TrackingResponse } from "../../models/trackingResponse.js";
import TrackingForm from "./TrackingForm.vue";
import TrackingList from "./TrackingList.vue";

export default {
  name: "Tracking",
  props: ["redirect"],
  data() {
    return {
      trackingData: window.TrackingData,
      list: null,
      loading: false,
      empty: false,
      sendError: false,
      mawb: "",
    };
  },
  components: { TrackingForm, TrackingList },
  methods: {
    send(event) {
      const vm = this;
      if (this.redirect) {
        window.location.href =
          this.redirect + "?" + vm.serialize2GetQuery({ mawb: event });
      } else {
        vm.loading = true;
        vm.empty = false;
        vm.sendError = false;
        this.$axiosJson
          .get(
            vm.trackingData.ajaxUrl +
              "?" +
              vm.serialize2GetQuery({ mawb: event })
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data && response.data.shipment) {
                this.list = new TrackingResponse(response.data.shipment);
              } else {
                if (response.data.error) {
                  console.error("trips error =>", response.data.error);
                  vm.sendError = true;
                } else {
                  this.list = null;
                }
              }
            }
            if (!this.list && !vm.sendError) {
              vm.empty = true;
            }
            vm.loading = false;
          });
      }
    },
  },
};
</script>
