<template>
  <div class="ua-datepicker" :class="classes">
    <b-datepicker
      icon="calendar-today"
      :value="value"
      :first-day-of-week="1"
      :placeholder="placeholder"
      :date-formatter="dateFormatter"
      :min-date="minDate"
      :disabled="isDisabled"
      :mobile-native="false"
      @input="onInput"
    ></b-datepicker>
    <div class="error-container">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: "UaDatepicker",
  props: {
    value: [String, Array, Object, Date],
    placeholder: {
      type: String,
    },
    minDate: {
      type: Date,
    },
    error: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const errorClass = this.error ? "error" : "";
      const successClass = !this.error && this.newValue ? "success" : "";
      return [errorClass, successClass];
    },
    errorMessage() {
      if (this.error === "required") {
        return this.$t("global.errors.required");
      } else if (this.error === "regex") {
        return this.$t("global.errors.regex");
      }
    },
  },
  methods: {
    dateFormatter(date) {
      return this.moment(date).format("DD.MM.YYYY");
    },
    onInput(newValue) {
      this.$emit("input", newValue);
      setTimeout(() => {
        this.$emit("change");
      }, 0);
    },
  },
};
</script>
