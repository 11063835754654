<template>
  <form class="search-form--form" autocomplete="off" @submit.prevent="send()">
    <div class="row align-items-start">
      <div class="col-sm-12 col-md-9 col-xl-10">
        <div class="form-group">
          <v-ua-input
            name="query"
            class="ua-input__single"
            :label="$t('search.form.labels.query')"
            v-model.trim="query"
            v-validate="{
              required: true,
              regex: /^.{3,}$/,
            }"
          ></v-ua-input>
          <div class="point--error" v-if="errors.first('query')">
            {{ $t("search.form.errors.query") }}
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-xl-2">
        <button
          type="button"
          class="tracking-form--btn btn btn-primary"
          @click="send()"
        >
          {{ $t("search.form.submitButton") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "SearchForm",
  data() {
    return {
      query: "",
    };
  },
  created() {
    if (window.SearchData.query) {
      this.query = window.SearchData.query;
      setTimeout(() => {
        this.send();
      }, 1000);
    }
  },
  methods: {
    send() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$emit("submit", this.query);
        }
      });
    },
  },
};
</script>

<style scoped></style>
