<template>
  <div class="ua-input" :class="classes">
    <input
      class="ua-input--input"
      autocomplete="off"
      v-if="onlyDigit"
      v-model="newValue"
      v-only-digit="onlyDigit"
      :disabled="isDisabled"
      :name="name"
      :type="inputType"
      :placeholder="placeholder"
      :maxlength="inputMaxLength"
      @focus="isEdit = true"
      @blur="onBlur"
      @input="onInput"
    />
    <input
      v-else
      autocomplete="off"
      class="ua-input--input"
      v-model="newValue"
      :name="name"
      :type="inputType"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :maxlength="inputMaxLength"
      @focus="isEdit = true"
      @blur="onBlur"
      @input="onInput"
    />
    <label class="ua-input--label" v-if="label">{{ label }}</label>
    <div class="error-container">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: "UaInput",
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    name: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    onlyDigit: String,
    numberAfterPoint: {
      type: Number,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputMaxLength() {
      return this.type === "time" ? 5 : null;
    },
    inputType() {
      return this.type === "time" ? "text" : this.type;
    },
    classes() {
      const activeClass = this.value || this.isEdit ? "active" : "";
      const errorClass = this.error ? "error" : "";
      const successClass = !this.error && this.newValue ? "success" : "";
      return [activeClass, errorClass, successClass];
    },
    errorMessage() {
      if (this.error === "required") {
        const firstPart = "«" + this.label.replace(" *", "").trim() + "»";
        return firstPart + " - " + this.$t("global.errors.requiredPart");
      } else if (this.error === "regex" || this.error === "min") {
        if (this.type === "email") {
          return this.$t("global.errors.regexEmail");
        }
        if (this.type === "phone") {
          return this.$t("global.errors.regexPhone");
        }
        return this.$t("global.errors.regex");
      }
    },
  },
  data() {
    return {
      newValue: null,
      isEdit: false,
    };
  },
  methods: {
    onBlur() {
      if (!this.placeholder) {
        this.isEdit = false;
      }
    },
    handlerTimeValue() {
      if (this.newValue.length === 3) {
        const hours = this.newValue.slice(0, 2);
        const minutes = this.newValue.slice(2, 4);
        if (this.newValue[2] !== ":") {
          this.newValue = hours + ":" + minutes;
        } else {
          this.newValue = hours;
        }
      }
    },
    handlerNumerAfterPoint() {
      const stringValue = this.newValue.toString();
      const haveComma = this.newValue.indexOf(",") !== -1;
      const splitType = haveComma ? "," : ".";

      const beforePoint = +stringValue.split(splitType)[0];
      const afterPoint = stringValue.split(splitType)[1];
      if (afterPoint && afterPoint.length > this.numberAfterPoint) {
        let slicedText = afterPoint.slice(0, this.numberAfterPoint);
        let slicedNumber = +("0" + splitType + slicedNumber);
        if (slicedNumber) {
          this.newValue = +(beforePoint + slicedNumber);
        } else {
          this.newValue = beforePoint + splitType + slicedText;
        }
      }
    },
    onInput() {
      if (this.numberAfterPoint) {
        this.handlerNumerAfterPoint();
      }
      if (this.type === "time") {
        this.handlerTimeValue();
      }
      this.$emit("input", this.newValue);
      setTimeout(() => {
        this.$emit("change");
      }, 0);
    },
  },
  created() {
    this.newValue = this.value;
    this.isEdit = !!this.placeholder;
  },
};
</script>
