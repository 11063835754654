<template>
  <div>
    <div class="row">
      <article
        class="col-lg-3 col-md-6 col-sm-12 news--block"
        v-for="(item, index) in news"
        :key="index"
      >
        <a class="news-block--img-link" :href="item.link"
          ><img :src="item.preview_picture"
        /></a>
        <a class="news-block--title-link" :href="item.link">
          <h5 class="news-block--title" v-html="item.menutitle"></h5>
        </a>
        <div class="news-block--content">
          <p class="news-block--announce" v-html="item.announce"></p>
          <p class="news-block--date">{{ item.date }}</p>
        </div>
      </article>
    </div>
    <div class="row news--row-loader" v-if="loading">
      <v-ua-spinner></v-ua-spinner>
    </div>
    <div class="row news--row-btn" v-if="more">
      <button type="button" class="btn btn-primary" @click="showMore">
        {{ $t("newsList.showMore") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  props: ["urlData", "count", "numpage"],
  data() {
    return {
      news: [],
      page: 1,
      loading: false,
    };
  },
  created() {
    this.getNews(true);
  },
  methods: {
    showMore() {
      this.page++;
      this.getNews();
    },
    getNews(firstData) {
      if (firstData) {
        this.news = window.NewsData.firstData;
      } else {
        this.loading = true;
        this.$axiosJson
          .get(this.urlData + "?page=" + this.page)
          .then((response) => {
            if (response.status === 200) {
              response.data.forEach((item) => {
                this.news.push(item);
              });
            }
            this.loading = false;
          });
      }
    },
  },
  computed: {
    more() {
      return this.page * Number(this.numpage) < Number(this.count);
    },
  },
};
</script>

<style scoped></style>
