export class ContactsResponseTransformer {
  constructor(response, type) {
    this.items = [];
    if (response && response.length) {
      response.forEach((item) => {
        if (item) {
          this.items.push(new ContactsResponseItem(item, type));
        }
      });
      if (this.items.length > 0) {
        this.items.sort((a, b) => b.sort - a.sort);
      }
    }
  }

  getItems() {
    return this.items;
  }
}
export class ContactsResponseItem {
  constructor(data, type) {
    // простые поля
    [
      "address",
      "city",
      "country",
      "customsOperationalHours",
      "dgStorage",
      "email",
      "intlCargoAccepted",
      "name",
      "phone",
      "shcsAccepted",
      "terminalOperationalHours",
      "zipCode",
      "latitude",
      "longitude",
      "operational_hours",
      "sort",
    ].forEach((field) => {
      this[field] = data[field];
    });
    this.type = type;

    if (this.latitude) {
      this.latitude = Number(this.latitude);
    }
    if (this.longitude) {
      this.longitude = Number(this.longitude);
    }
  }
}
