<template>
  <div class="col-xl-12">
    <!-- <yandex-map
      :coords="[coordX, coordY]"
      :zoom="zoom"
      style="height: 350px"
      :cluster-options="{
        1: { clusterDisableClickZoom: true },
      }"
      :behaviors="['drag']"
      :controls="['zoomControl']"
      :placemarks="placemarks"
      :options="options"
    >
    </yandex-map> -->
    <section class="contacts-form--list">
      <h3 class="visually-hidden">{{ $t("contacts.list.contactList") }}</h3>
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="{ 'contacts-form--list-row__only': list.length == 1 }"
        class="row contacts-form--list-row"
      >
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-12">
              <h4 class="contacts-form--list-title">
                {{ item.name }}
              </h4>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-3 col-md-6 col-sm-12 contacts-form--item contacts-form--item__adress"
            >
              <p class="contacts-form--item-name">
                {{ $t("contacts.list.address") }}
              </p>
              <p>{{ item.address }}</p>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-12 contacts-form--item contacts-form--item__email"
            >
              <p class="contacts-form--item-name">E-mail</p>
              <p>{{ item.email }}</p>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-12 contacts-form--item contacts-form--item__phone"
            >
              <p class="contacts-form--item-name">
                {{ $t("contacts.list.phone") }}
              </p>
              <p>{{ item.phone }}</p>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-12 contacts-form--item contacts-form--item__hours"
            >
              <p class="contacts-form--item-name">
                {{ $t("contacts.list.modeOperation") }}
              </p>
              <p>
                {{ item.operational_hours }}
              </p>
              <!-- <p>
                {{ $t("contacts.list.terminal") }}:
                {{ item.terminalOperationalHours }}
              </p>
              <p>
                {{ $t("contacts.list.customs") }}:
                {{ item.customsOperationalHours }}
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContactsList",
  props: ["list"],
  data() {
    return {
      width: 1110,
      height: 350,
      options: {
        apiKey: "5ecf7932-a9ca-4474-9648-ffa776112951",
        lang: "ru_RU",
        version: "2.1",
      },
      coordX: "56.862130430458",
      coordY: "60.541542183853",
      zoom: 12,
      placemarks: [],
    };
  },
  methods: {
    getBounds() {
      const centroid = [0, 0];
      const latitudes = [];
      const longitudes = [];
      let totalPoints = 0;
      const placemarks = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].latitude && this.list[i].longitude) {
          totalPoints++;
          latitudes.push(this.list[i].latitude);
          longitudes.push(this.list[i].longitude);
          placemarks.push({
            coords: [this.list[i].latitude, this.list[i].longitude],
            openTimeout: 150,
            properties: {}, // define properties here
            options: {}, // define options here
            clusterName: "1",
            balloonTemplate: `<div><b>${this.list[i].name}</b><p>${this.list[i].address}</p><p>${this.list[i].email}</p><p>${this.list[i].phone}</p></div>`,
          });
        }
      }
      if (totalPoints > 1) {
        const Xmin = Math.min(...longitudes);
        const Xmax = Math.max(...longitudes);
        const Ymin = Math.min(...latitudes);
        const Ymax = Math.max(...latitudes);
        const maxY = Ymax - Ymin;
        const maxX = Xmax - Xmin;
        const zoomX = Math.ceil((40 * this.width) / (maxX * 350));
        const zoomY = Math.ceil((9 * this.height) / (maxY * 350));
        centroid[1] = (Xmax + Xmin) / 2;
        centroid[0] = (Ymax + Ymin) / 2;
        return {
          coordX: centroid[0],
          coordY: centroid[1],
          zoom: Math.min(12, zoomX, zoomY),
          placemarks: placemarks,
        };
      } else {
        return {
          coordX: centroid[0],
          coordY: centroid[1],
          zoom: 12,
          placemarks: placemarks,
        };
      }
    },
  },
  created() {
    const bounds = this.getBounds();
    this.coordX = bounds.coordX;
    this.coordY = bounds.coordY;
    this.zoom = bounds.zoom;
    this.placemarks = bounds.placemarks;
  },
};
</script>

<style scoped></style>
