export default {
  ru: {
    startYear: 1900,
    endYear: 2099,
    dateFormat: "dd-MM-yyyy",
    days: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
    shortDays: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    shorterDays: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    shortMonths: [
      "Янв",
      "Фев",
      "Март",
      "Апр",
      "Май",
      "Июнь",
      "Июль",
      "Авг",
      "Сент",
      "Окт",
      "Нояб",
      "Дек",
    ],
    shorterMonths: [
      "J",
      "F",
      "M",
      "A",
      "M",
      "Ju",
      "Ju",
      "A",
      "Se",
      "O",
      "N",
      "D",
    ],
    firstDayOfAWeek: 1,
  },
  en: {
    startYear: 1900,
    endYear: 2099,
    dateFormat: "yyyy-MM-dd",
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    shorterDays: ["S", "M", "T", "W", "T", "F", "S"],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    shorterMonths: [
      "J",
      "F",
      "M",
      "A",
      "M",
      "Ju",
      "Ju",
      "A",
      "Se",
      "O",
      "N",
      "D",
    ],
    firstDayOfAWeek: 1,
  },
};
