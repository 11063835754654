<template>
  <div class="cookie-layer" v-if="!isCookie">
    <div class="wrapper">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12 col-md-9">
              <p class="cookie-layer--text">
                {{ mess }}
              </p>
            </div>

            <div class="col-sm-12 col-md-3">
              <button
                type="button"
                @click="agree"
                class="cookie-layer--btn form-btn btn btn-primary"
              >
                {{ btn }}
                <!-- <b-tooltip v-if="isWide">{{ mess }}</b-tooltip> -->
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieAttention",
  data() {
    return {
      mess: "",
      btn: "",
      buttonText: "Cookie",
      isWide: true,
      isCookie: false,
    };
  },
  created() {
    this.mess = this.$t("cookieAttention.mess");
    this.btn = this.$t("cookieAttention.btn");
    this.isWide = !window.matchMedia("(max-width: 1280px)").matches;
    if (!this.isWide) {
      this.buttonText = this.btn;
    }
    this.isCookie = this.$cookie.get("attention_cookie_disable");
  },
  methods: {
    mouseOver() {
      if (this.isWide) {
        this.buttonText = this.btn;
      }
    },
    mouseOut() {
      if (this.isWide) {
        this.buttonText = "Cookie";
      }
    },
    agree() {
      this.$cookie.set("attention_cookie_disable", 1, { expires: "10Y" });
      this.isCookie = true;
    },
  },
};
</script>

<style scoped></style>
