<template>
  <div :class="{ expanded: expanded }">
    <div class="row">
      <TripsForm
        :shcs="tripsData.shcs"
        :airports="airports"
        @submit="send"
        @expanded="expandedForm"
      ></TripsForm>
    </div>
    <div class="row justify-content-center" v-if="!loading && empty">
      {{ $t("trips.list.emptyResult") }}
    </div>
    <div class="row justify-content-center" v-if="!loading && sendError">
      {{ $t("trips.list.sendError") }}
    </div>
    <div
      class="row justify-content-center"
      v-if="
        !loading &&
        !sendError &&
        !empty &&
        (!list || list.length == 0) &&
        !redirect
      "
    >
      {{ $t("trips.list.defaultText") }}
    </div>
    <div class="row" v-if="!loading && list && list.length > 0">
      <TripList :list="list"></TripList>
    </div>
    <div class="row" v-if="loading">
      <v-ua-spinner></v-ua-spinner>
    </div>
  </div>
</template>

<script>
import { Airport } from "../../models/Airport";
import { TripsResponseTransformer } from "../../models/tripsResponse";
import TripsForm from "./TripsForm.vue";
import TripList from "./TripList.vue";

export default {
  name: "Trips",
  props: ["redirect", "toHeader"],
  data() {
    return {
      tripsData: window.TripsData,
      airports: [],
      list: [],
      loading: false,
      empty: false,
      sendError: false,
      expanded: false,
    };
  },
  components: { TripsForm, TripList },
  mounted() {
    this.setAirports();
    if (this.toHeader) {
      const underheader = window.document.getElementById("underheader_block");
      if (underheader) {
        underheader.classList.add("underheader__lg");
      }
    }
  },
  methods: {
    expandedForm(event) {
      this.expanded = event;
      if (this.toHeader) {
        const underheader = window.document.getElementById("underheader_block");
        if (underheader) {
          if (event) {
            underheader.classList.remove("underheader__lg");
            underheader.classList.add("underheader__xl");
          } else {
            underheader.classList.add("underheader__lg");
            underheader.classList.remove("underheader__xl");
          }
        }
      }
    },
    setAirports() {
      const vm = this;
      this.$axiosJson.get(vm.tripsData.airportsUrl).then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.airports
        ) {
          vm.airports = response.data.airports.map((data) => new Airport(data));
          vm.airports.sort((a, b) =>
            a.sortStr > b.sortStr ? 1 : b.sortStr > a.sortStr ? -1 : 0
          );
        }
      });
    },
    send(event) {
      const vm = this;
      if (this.redirect) {
        window.location.href =
          this.redirect + "?" + vm.serialize2GetQuery(event);
      } else {
        vm.loading = true;
        vm.empty = false;
        vm.sendError = false;
        this.$axiosJson
          .get(vm.tripsData.ajaxUrl + "?" + vm.serialize2GetQuery(event))
          .then((response) => {
            if (response.status === 200) {
              if (response.data.trips) {
                const transformer = new TripsResponseTransformer(
                  response.data.trips
                );
                this.list = transformer.getItems();
              } else {
                if (response.data.error) {
                  console.error("trips error =>", response.data.error);
                  vm.sendError = true;
                  this.list = [];
                } else {
                  this.list = [];
                }
              }
            }
            if (!this.list.length && !vm.sendError) {
              vm.empty = true;
            }
            vm.loading = false;
          });
      }
    },
  },
};
</script>
