<template>
  <div class="ua-checkbox">
    <b-checkbox @input="onInput" :value="value" />
  </div>
</template>

<script>
export default {
  name: "UaCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
