export class Airport {
  constructor(data) {
    this.id = Number(data.id);
    this.name_ru = data.name_ru;
    this.name_en = data.name_en;
    this.country_id = Number(data.country_id);
    this.iata = data.iata;
    this.search_string = data.search_string;
    this.sortPriority = data.sortPriority;
    this.setSortStr();
  }

  get name() {
    const name =
      window.Vue.prototype.currentLocale() === "ru"
        ? this.name_ru
        : this.name_en;
    return name ? name : this.iata;
  }

  setSortStr() {
    let sortStr = "";
    // Если задана приоритетная сортировка у города
    if (this.sortPriority) {
      if (this.sortPriority < 10) {
        sortStr = "00" + this.sortPriority;
      } else if (this.sortPriority < 100) {
        sortStr = "0" + this.sortPriority;
      } else if (this.sortPriority > 999) {
        sortStr = this.sortPriority.substr(0, 3);
      }
    } else {
      sortStr = "999";
    }
    sortStr += this.name.substr(0, 4);
    this.sortStr = sortStr;
  }

  toString() {
    return this.name;
  }
}
