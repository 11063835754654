<template>
  <div v-on-clickaway="close" :class="{ invalid: invalid }" class="point">
    <div class="point--fields" v-if="!isEdit && value" @click="edit">
      {{ value.name }}<template v-if="value.iata"> ({{ value.iata }})</template>
    </div>
    <div class="point--fields" v-if="!isEdit && !value" @click="edit">
      {{ placeholder }}
    </div>
    <div class="point--fields point--fields__opened" v-if="isEdit">
      <input
        class="point--input"
        type="text"
        v-model="searchTerm"
        ref="searchInput"
      />
      <ul class="point--options" v-if="result.length > 0">
        <li
          class="point--option"
          v-for="point in result"
          @click="select(point)"
          :key="point.id"
        >
          {{ point.name
          }}<template v-if="point.iata"> ({{ point.iata }})</template>
        </li>
      </ul>
      <ul class="point--options" v-if="result.length == 0">
        <li class="point--option">{{ emptyResult }}</li>
      </ul>
    </div>
    <div class="point--error" v-if="invalid">{{ error }}</div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
export default {
  name: "Point",
  props: [
    "value",
    "points",
    "placeholder",
    "excludePoint",
    "emptyResult",
    "error",
    "invalid",
    "country",
  ],
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      isEdit: false,
      searchTerm: "",
    };
  },
  methods: {
    edit() {
      this.isEdit = true;
      this.searchTerm = "";
      setTimeout(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus();
        }
      }, 300);
    },
    close() {
      this.isEdit = false;
    },
    getResult() {
      if (!this.search) {
        return this.points.filter(
          (point) => !this.excludePoint || this.excludePoint.id !== point.id
        );
      }
    },
    select(point) {
      this.close();
      this.$emit("input", point);
    },
  },
  computed: {
    result() {
      let result;
      if (!this.searchTerm) {
        result = this.points
          .filter(
            (point) => !this.excludePoint || this.excludePoint.id !== point.id
          )
          .filter(
            (point) => !this.country || this.country.id == point.country_id
          );
      } else {
        const term = this.searchTerm.toString().toLowerCase();

        result = this.points
          .filter(
            (point) => !this.excludePoint || this.excludePoint.id !== point.id
          )
          .filter(
            (point) => !this.country || this.country.id == point.country_id
          )
          .filter((point) => point.search_string.toLowerCase().includes(term));
      }
      return result ? result : [];
    },
  },
};
</script>
