<template>
  <div class="wrapper">
    <div class="row">
      <ContactsForm
        :airports="cities"
        :countries="countries"
        :airportIata="contactsData.airportIata"
        :countryAlpha="contactsData.countryAlpha"
        :toHeader="toHeader"
        @submit="send"
      ></ContactsForm>
    </div>
    <div class="row justify-content-center" v-if="!loading && empty">
      {{ $t("contacts.list.emptyResult") }}
    </div>
    <div class="row justify-content-center" v-if="!loading && sendError">
      {{ $t("contacts.list.sendError") }}
    </div>
    <div
      class="row justify-content-center"
      v-if="
        !loading &&
        !sendError &&
        !empty &&
        (!list || list.length == 0) &&
        !redirect
      "
    >
      {{ $t("contacts.list.defaultText") }}
    </div>
    <div class="row" v-if="!loading && list">
      <ContactsList :list="list"></ContactsList>
    </div>
    <div class="row" v-if="loading">
      <v-ua-spinner></v-ua-spinner>
    </div>
  </div>
</template>

<script>
import { Airport } from "../../models/Airport";
import { City } from "../../models/City";
import { Country } from "../../models/Country";
import ContactsForm from "./ContactsForm.vue";
import ContactsList from "./ContactsList.vue";
import { ContactsResponseTransformer } from "../../models/contactsResponse.js";

export default {
  name: "Contacts",
  props: {
    redirect: {
      default: false,
    },
    toHeader: {
      default: false,
    },
  },
  components: { ContactsForm, ContactsList },
  data() {
    return {
      contactsData: window.ContactsData,
      list: null,
      loading: false,
      empty: false,
      sendError: false,
      airports: [],
      cities: [],
      countries: [],
    };
  },
  mounted() {
    this.setAirports();
    this.setCities();
  },
  methods: {
    setAirports() {
      const vm = this;
      this.$axiosJson.get(vm.contactsData.airportsUrl).then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.airports &&
          response.data.countries
        ) {
          vm.airports = response.data.airports.map((data) => new Airport(data));
          vm.countries = response.data.countries.map(
            (data) => new Country(data)
          );
          vm.airports.sort((a, b) =>
            a.sortStr > b.sortStr ? 1 : b.sortStr > a.sortStr ? -1 : 0
          );
          vm.countries.sort((a, b) =>
            a.sortStr > b.sortStr ? 1 : b.sortStr > a.sortStr ? -1 : 0
          );
        }
      });
    },
    setCities() {
      const vm = this;
      this.$axiosJson.get("/ajax/countries-cities.php").then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.cities &&
          response.data.countries
        ) {
          vm.cities = response.data.cities.map((data) => new City(data));
          vm.countries = response.data.countries.map(
            (data) => new Country(data)
          );
          vm.cities.sort((a, b) =>
            a.sortStr > b.sortStr ? 1 : b.sortStr > a.sortStr ? -1 : 0
          );
          vm.countries.sort((a, b) =>
            a.sortStr > b.sortStr ? 1 : b.sortStr > a.sortStr ? -1 : 0
          );
        }
      });
    },
    send(event) {
      this.loading = true;
      this.empty = false;
      this.sendError = false;
      this.$axiosJson
        .get(this.contactsData.ajaxUrl + "?" + this.serialize2GetQuery(event))
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              let transformer;
              if (response.data.handlers && response.data.handlers.length > 0) {
                transformer = new ContactsResponseTransformer(
                  response.data.handlers,
                  "handlers"
                );
                this.list = transformer.getItems();
              } else {
                this.list = null;
              }
              if (
                response.data.forwarders &&
                response.data.forwarders.length > 0
              ) {
                transformer = new ContactsResponseTransformer(
                  response.data.forwarders,
                  "forwarders"
                );
                if (!this.list) {
                  this.list = [];
                }
                transformer.getItems().forEach((item) => {
                  this.list.push(item);
                });
              }
              if (!this.list && response.data.error) {
                this.sendError = true;
              }
            }
          }
          if (!this.list && !this.sendError) {
            this.empty = true;
          }
          this.loading = false;
        });
    },
  },
};
</script>
