const prepareDigitChange = (el, binding, vnode) => {
  if (binding.value === "int") {
    const reg = /^\d+$/;
    if (!reg.test(el.value)) {
      el.value = "";
    }
  } else {
    const value = el.value.replace(",", ".");
    if (value && (isNaN(parseFloat(value)) || !isFinite(value))) {
      el.value = "";
    }
  }
  vnode.elm.dispatchEvent(new CustomEvent("input"));
};
export default {
  bind(el, binding, vnode) {
    el.addEventListener("keydown", (e) => {
      if (
        [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      if (binding.value === "int") {
        const reg = /\d/;
        if (!reg.test(e.key)) {
          e.preventDefault();
        }
      } else {
        let value = el.value + e.key;
        value = value.replace(",", ".");
        if (value && (isNaN(parseFloat(value)) || !isFinite(value))) {
          e.preventDefault();
        }
      }
    });
    el.addEventListener("paste", () => {
      setTimeout(() => {
        prepareDigitChange(el, binding, vnode);
      }, 300);
    });
    el.addEventListener("change", () => {
      prepareDigitChange(el, binding, vnode);
    });
  },
};
