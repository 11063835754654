<template>
  <form class="trips-form--form" autocomplete="off" v-if="formData">
    <div class="row trips-form--row">
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <v-point
          v-model="formData.origin"
          @input="checkValidate('origin')"
          :points="airports"
          :placeholder="$t('trips.form.labels.origin')"
          :empty-result="$t('trips.form.autocompleteEmpty')"
          :exclude-point="formData.destination"
          v-validate="'required'"
          key="origin"
          name="origin"
          class="point__transparent"
          :error="$t('trips.form.errors.origin')"
          :invalid="errors.first('origin') && !changedFields.origin"
        ></v-point>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <v-point
          v-model="formData.destination"
          @input="checkValidate('destination')"
          :points="airports"
          :empty-result="$t('trips.form.autocompleteEmpty')"
          :placeholder="$t('trips.form.labels.destination')"
          :exclude-point="formData.origin"
          v-validate="'required'"
          name="destination"
          class="point__transparent"
          :error="$t('trips.form.errors.destination')"
          :invalid="errors.first('destination') && !changedFields.destination"
          key="destination"
        ></v-point>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <b-datepicker
          :first-day-of-week="1"
          class="trips-form--datepicker form-input form-input__date is-right"
          v-model="formData.date"
          :placeholder="$t('trips.form.labels.date')"
          :date-formatter="dateFormatter"
          :min-date="yesterday"
          v-validate="'required'"
          icon="calendar-today"
        ></b-datepicker>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-btn">
        <button
          type="button"
          class="form-btn form-btn__desktop btn btn-primary form-input form-input__submit"
          @click="send()"
        >
          {{ $t("trips.form.submitButton") }}
        </button>
      </div>
    </div>

    <div class="row trips-form--row" v-if="showOptions">
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="form-group">
          <v-ua-input
            class="trips-form--input-wrapper"
            name="pieces"
            :label="$t('trips.form.labels.pieces')"
            v-model="formData.pieces"
            v-validate="'integer'"
            only-digit="int"
          ></v-ua-input>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="form-group">
          <v-ua-input
            class="trips-form--input-wrapper"
            name="weight"
            :label="$t('trips.form.labels.weight')"
            v-model="formData.weight"
            only-digit="float"
          ></v-ua-input>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="form-group">
          <v-ua-input
            class="trips-form--input-wrapper"
            name="volume"
            :label="$t('trips.form.labels.volume')"
            v-model="formData.volume"
            only-digit="float"
          ></v-ua-input>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="form-group trips-form--dropdown" v-if="shcs">
          <TripShcsSelect
            v-model="formData.shcs"
            :shcs="shcs"
            :placeholder="$t('trips.form.labels.shcs')"
          ></TripShcsSelect>
        </div>
      </div>
    </div>
    <div class="row banner--more-options"></div>
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <button type="button" class="trips-form--more-options" @click="open">
          <span v-if="!showOptions">
            <i class="material-icons">keyboard_arrow_down</i>
          </span>
          <span v-if="showOptions">
            <i class="material-icons">keyboard_arrow_up</i>
          </span>
          {{ $t("trips.form.showOptionsButton") }}
        </button>
      </div>
      <div class="col-sm-12 col-md-8">
        <button
          type="button"
          class="tracking-form--btn btn btn-primary form-btn form-btn__mobile btn btn-primary"
          @click="send()"
        >
          {{ $t("trips.form.submitButton") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { TripsFormData } from "../../models/TripsFormData";
import TripShcsSelect from "./TripShcsSelect.vue";

export default {
  name: "TripsForm",
  props: ["shcs", "airports"],
  components: { TripShcsSelect },
  data() {
    return {
      formData: new TripsFormData(window.TripsData.formData, []),
      showOptions: false,
      searchedCountries: [],
      originAirports: [],
      destinationAirports: [],
      yesterday: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      validated: false,
      changedFields: {
        origin: false,
        destination: false,
      },
    };
  },
  watch: {
    airports() {
      this.initFormData();
    },
  },
  methods: {
    dateFormatter(date) {
      return this.moment(date).format("DD.MM.YYYY");
    },
    open() {
      this.showOptions = !this.showOptions;
      this.$emit("expanded", this.showOptions);
    },
    initFormData() {
      this.formData = new TripsFormData(
        window.TripsData.formData,
        this.airports
      );
      if (
        (this.formData.shcs && this.formData.shcs.length > 0) ||
        this.formData.pieces ||
        this.formData.weight ||
        this.formData.volume
      ) {
        this.open();
      }
      if (
        this.formData.date &&
        this.formData.origin &&
        this.formData.destination
      ) {
        this.send();
      }
    },
    searchOrigin(searchTerm) {
      this.originAirports = new Promise((resolve) => {
        if (!searchTerm) {
          resolve(
            this.airports.filter(
              (airport) =>
                !this.formData.destination ||
                this.formData.destination.id !== airport.id
            )
          );
        } else {
          const term = searchTerm.toString().toLowerCase();

          resolve(
            this.airports
              .filter(
                (airport) =>
                  !this.formData.destination ||
                  this.formData.destination.id !== airport.id
              )
              .filter((country) =>
                country.search_string.toLowerCase().includes(term)
              )
          );
        }
      });
    },
    searchDestination(searchTerm) {
      this.destinationAirports = new Promise((resolve) => {
        if (!searchTerm) {
          resolve(
            this.airports.filter(
              (airport) =>
                !this.formData.origin || this.formData.origin.id !== airport.id
            )
          );
        } else {
          const term = searchTerm.toString().toLowerCase();

          resolve(
            this.airports
              .filter(
                (airport) =>
                  !this.formData.origin ||
                  this.formData.origin.id !== airport.id
              )
              .filter((airport) =>
                airport.search_string.toLowerCase().includes(term)
              )
          );
        }
      });
    },
    checkValidate(field) {
      this.changedFields[field] = true;
    },
    send() {
      this.$validator.validate().then((result) => {
        this.changedFields.destination = false;
        this.changedFields.origin = false;
        if (result) {
          this.$emit("submit", this.formData.getData());
        }
      });
    },
  },
};
</script>

<style scoped></style>
