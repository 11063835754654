<template>
  <div class="form-container" v-on-clickaway="close">
    <form class="form-inline my-2 my-lg-0" @submit.prevent="send()">
      <transition name="fade">
        <div class="form--section" v-show="show">
          <input
            :class="{ filled: query }"
            class="form-control mr-sm-2"
            type="text"
            name="query"
            :placeholder="$t('search.form.labels.query')"
            :aria-label="$t('search.form.labels.query')"
            v-validate="{ required: true }"
            v-model.trim="query"
          />
        </div>
      </transition>
      <button class="search-btn" v-on:click="searchButtonClick" type="button">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
          >
            <g id="surface1">
              <path
                style="
                  stroke: none;
                  fill-rule: nonzero;
                  fill: rgb(255, 255, 255);
                  fill-opacity: 1;
                "
                d="M 19.359375 18.21875 L 14.601562 13.265625 C 15.824219 11.8125 16.496094 9.980469 16.496094 8.074219 C 16.496094 3.621094 12.871094 0 8.421875 0 C 3.96875 0 0.34375 3.621094 0.34375 8.074219 C 0.34375 12.527344 3.96875 16.148438 8.421875 16.148438 C 10.09375 16.148438 11.683594 15.644531 13.046875 14.6875 L 17.84375 19.675781 C 18.042969 19.886719 18.3125 20 18.601562 20 C 18.875 20 19.136719 19.894531 19.332031 19.707031 C 19.75 19.304688 19.765625 18.636719 19.359375 18.21875 Z M 8.421875 2.105469 C 11.710938 2.105469 14.390625 4.785156 14.390625 8.074219 C 14.390625 11.367188 11.710938 14.042969 8.421875 14.042969 C 5.128906 14.042969 2.453125 11.367188 2.453125 8.074219 C 2.453125 4.785156 5.128906 2.105469 8.421875 2.105469 Z M 8.421875 2.105469 "
              />
            </g>
          </svg>
        </span>
      </button>
    </form>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";
export default {
  name: "SearchFlexForm",
  data() {
    return {
      show: false,
      query: "",
    };
  },
  directives: {
    onClickaway: onClickaway,
  },
  methods: {
    send() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$emit("submit", this.query);
        }
      });
    },
    searchButtonClick() {
      if (!this.show) {
        this.open();
      } else {
        this.send();
      }
    },
    close() {
      this.$root.$el.classList.remove("search-open");
      this.show = false;
    },
    open() {
      this.$root.$el.classList.add("search-open");
      this.show = true;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
