import "./scss/main.scss";

import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

/* plugins */
import VeeValidate from "vee-validate";
import VueCookie from "vue-cookie";
import Buefy from "buefy";

/* configs */
import DatepickerLocale from "../local_modules/plugin-locales/datepicker/datepicker.js";
/* Components */

import Trips from "./components/trips/Trips.vue";
import Tracking from "./components/tracking/Tracking.vue";
import Contacts from "./components/contacts/Contacts.vue";
import LanguageMenu from "./components/header/LanguageMenu.vue";
import CookieAttention from "./components/site-widgets/CookieAttention.vue";
import News from "./components/news/News.vue";
import Point from "./components/utils/Point.vue";
import UaInput from "./components/utils/UaInput.vue";
import UaTextarea from "./components/utils/UaTextarea.vue";
import UaCheckbox from "./components/utils/UaCheckbox.vue";
import UaSpinner from "./components/utils/UaSpinner.vue";
import UaDatepicker from "./components/utils/UaDatepicker.vue";
import Search from "./components/search/Search.vue";
import FreightCharterOrder from "./components/freight-charter-order/FreightCharterOrder.vue";

/* Directives */
import OnlyDigit from "./directives/OnlyDigit.js";
import InputReadOnly from "./directives/InputReadOnly.js";

window.Vue = Vue;

require("./bootstrap");

const locale = window.Vue.prototype.currentLocale();
const localeForDatepicker = locale === "ru" ? "ru" : "en";
Vue.use(Buefy, {
  defaultDayNames: DatepickerLocale[localeForDatepicker].shortDays,
  defaultMonthNames: DatepickerLocale[localeForDatepicker].months,
});
Vue.use(VeeValidate, {
  events: "change",
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});
Vue.use(Vuex);
Vue.use(VueCookie);

const store = new Vuex.Store();
Vue.use(vuexI18n.plugin, store);
Vue.component("yandex-map", yandexMap);
Vue.component("ymap-marker", ymapMarker);

Vue.component("v-tracking", Tracking);

Vue.component("v-trips", Trips);
Vue.component("v-contacts", Contacts);
Vue.component("v-language-menu", LanguageMenu);
Vue.component("v-cookie-attention", CookieAttention);
Vue.component("v-news", News);
Vue.component("v-point", Point);
Vue.component("v-ua-input", UaInput);
Vue.component("v-ua-textarea", UaTextarea);
Vue.component("v-ua-checkbox", UaCheckbox);
Vue.component("v-ua-datepicker", UaDatepicker);
Vue.component("v-ua-spinner", UaSpinner);
Vue.component("v-search", Search);
Vue.component("v-freight-charter-order", FreightCharterOrder);

Vue.directive("only-digit", OnlyDigit);
Vue.directive("input-readonly", InputReadOnly);

new Vue({
  el: "#app",
  store,
  created() {
    const locale = this.currentLocale();
    this.$validator.locale = locale;
    Vue.i18n.add(locale, window.vueTranslate);
    Vue.i18n.set(locale);
  },
});
